import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Icon } from '@src/components/Icons/Icons';
import { useMember } from '@src/services/member/MemberProvider';
import { GetDeviceInfoProps } from '@src/utils/hooks/api/types/getDeviceInfo';
import { ValidateDeviceSwapProps } from '@src/utils/hooks/api/types/validateDeviceSwap';

interface IChangeAccessBody {
    deviceInfo: GetDeviceInfoProps;
    validateDeviceSwap: ValidateDeviceSwapProps;
}

export const ChangeAccessWarning = ({ deviceInfo, validateDeviceSwap }: IChangeAccessBody) => {
    const { t } = useTranslation();

    const {
        state: { accessType }
    } = useMember();

    const { deviceId, deviceDescription } = deviceInfo || {};

    const isCardType = accessType === 'card';
    const [warning, setWarning] = useState('');

    const canSwapDevice =
        validateDeviceSwap?.swapCountLeft != 0 || validateDeviceSwap?.allowMshpDeviceSwap;

    useEffect(() => {
        if (!validateDeviceSwap) return;

        if (!isCardType && deviceId) {
            deviceInfo.deviceDescription ||= t('phone');
        }

        if (!isCardType && !deviceInfo) return;

        if (!isCardType && !deviceDescription && !canSwapDevice) {
            setWarning(t('access-change.warning'));
        }

        setWarning((prevData) => prevData?.replace('{date}', validateDeviceSwap?.nextSwapDate));
    }, [deviceInfo, isCardType]);

    return (
        <>
            {warning && (
                <div className={'flex mt-xs gap-xxs text-status-red'}>
                    <Icon id={'info-outline'} className="flex items-center transform-none h-m" />
                    <Text
                        size={2}
                        dangerouslySetInnerHTML={{
                            __html: warning
                        }}
                    />
                </div>
            )}
        </>
    );
};
