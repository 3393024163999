import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { CheckBox } from '@src/components/Form/Checkbox/Checkbox';
import { DateInput } from '@src/components/Form/DateInput/DateInput';
import { Form } from '@src/components/Form/Form';
import { RadioButtonInput } from '@src/components/Form/RadioButtonInput/RadioButtonInput.component';
import { TextInput } from '@src/components/Form/TextInput/TextInput';
import { Skeleton } from '@src/components/Skeletons/Skeleton';
import { createSecondaryMember } from '@src/services/data/createSecondaryMember';
import { interpolate } from '@src/services/translations/interpolate';
import { email } from '@src/utils/helpers/regex';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { DateTime } from 'luxon';

import { RegistrationError } from './SecondaryMemberRegisterError.component';

const getMinBirthDate = () => DateTime.now().minus({ years: 100 }).toISODate();
const getMaxBirthDate = () => DateTime.now().minus({ years: 12 }).toISODate();

interface SecondaryMemberRegistrationFormProps {
    token: string;
    onRegistrationSuccess: () => void;
    onRegistrationError: (error: any) => void;
}

export const SecondaryMemberRegistrationForm = ({
    token,
    onRegistrationSuccess,
    onRegistrationError
}: SecondaryMemberRegistrationFormProps) => {
    const { t } = useTranslation();

    const {
        data: validateInviteResult,
        isError: validateInviteError,
        isLoading
    } = useFetch<any>(`/secondary-member/validate-invite-token/${token}`, true);

    const [isSubmittingForm, setIsSubmittingForm] = React.useState(false);
    const [success, setSuccess] = useState(false);

    const onFormSubmit = async (payload: Record<string, string>) => {
        setIsSubmittingForm(true);

        try {
            await createSecondaryMember({ ...payload, token });
            setIsSubmittingForm(false);
            setSuccess(true);
            onRegistrationSuccess();
        } catch (error: any) {
            setIsSubmittingForm(false);
            onRegistrationError(
                error?.response?.data?.errorCode || RegistrationError.SOMETHING_WENT_WRONG
            );
            return;
        }
    };

    React.useEffect(() => {
        validateInviteError &&
            onRegistrationError(
                validateInviteError?.data?.errorCode || RegistrationError.SOMETHING_WENT_WRONG
            );
    }, [isLoading]);

    return (
        <>
            {isLoading && <Skeleton type="text" lines={2.5} />}

            {!isLoading && (
                <Form
                    name="secondaryMemberRegistration"
                    onSubmit={onFormSubmit}
                    className="mt-s space-y-xs"
                >
                    <Text size={2}>
                        {interpolate(t('secondary_member.form.heading'), {
                            '{ main_member_first_name }': validateInviteResult?.data?.name || ''
                        })}
                    </Text>

                    <RadioButtonInput
                        name="gender"
                        radios={[
                            {
                                value: 'male',
                                label: t('secondary_member.form.title.male')
                            },
                            {
                                value: 'female',
                                label: t('secondary_member.form.title.female')
                            }
                        ]}
                        required={{
                            value: true,
                            message: t('secondary_member.form.errors.title_required')
                        }}
                    />
                    <TextInput
                        name="firstName"
                        required={{
                            value: true,
                            message: t('secondary_member.form.errors.first_name_required')
                        }}
                        label={t('secondary_member.form.first_name')}
                    />
                    <TextInput
                        name="lastName"
                        required={{
                            value: true,
                            message: t('secondary_member.form.errors.last_name_required')
                        }}
                        label={t('secondary_member.form.last_name')}
                    />
                    <DateInput
                        name="dateOfBirth"
                        min={{
                            value: getMinBirthDate(),
                            message: t('secondary_member.form.errors.min_birth_date')
                        }}
                        max={{
                            value: getMaxBirthDate(),
                            message: t('secondary_member.form.errors.max_birth_date')
                        }}
                        required={{
                            value: true,
                            message: t('secondary_member.form.errors.birth_date_required')
                        }}
                        label={t('secondary_member.form.birth_date')}
                    />

                    <TextInput
                        name="phone"
                        required={{
                            value: true,
                            message: t('secondary_member.form.errors.phone_required')
                        }}
                        minLength={{
                            value: 5,
                            message: t('secondary_member.form.errors.phone_format_invalid')
                        }}
                        pattern={{
                            value: /^[0-9]*$/,
                            message: t('secondary_member.form.errors.phone_format_invalid')
                        }}
                        label={t('secondary_member.form.phone')}
                    />

                    <TextInput
                        name="email"
                        required={{
                            value: true,
                            message: t('secondary_member.form.errors.email_required')
                        }}
                        pattern={{
                            value: email,
                            message: t('secondary_member.form.errors.email_pattern')
                        }}
                        label={t('secondary_member.form.email')}
                    />

                    <CheckBox
                        name="marketingConsent"
                        label={t('secondary_member.form.promotional_content')}
                    />

                    <CheckBox
                        name="agreeOnTerms"
                        required={{
                            value: true,
                            message: t('secondary_member.form.errors.terms_and_conditions_required')
                        }}
                        label={t('secondary_member.form.terms_and_conditions')}
                    />
                    <Button
                        icon={success ? 'tick' : ''}
                        type="submit"
                        text={success ? t('242') : t('secondary_member.form.submit_button')}
                        loading={isSubmittingForm}
                        disabled={isSubmittingForm}
                    />
                </Form>
            )}
        </>
    );
};
