import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Skeleton } from '@src/components/Skeletons/Skeleton';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Bold } from '@src/components/Text/Bold';
import { CancellationData } from '@src/pages/CancelMembership/CancelMembership.types';
import { useMember } from '@src/services/member/MemberProvider';
import { formatDate, getLocalDateString } from '@src/utils/helpers/date';
import { getLastInvoiceDate } from '@src/utils/helpers/memberHelpers';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { v4 as uuidv4 } from 'uuid';

export const CancelledMembership = () => {
    const { t } = useTranslation();
    const { state: member } = useMember();

    const {
        data: cancelMembershipData,
        isLoading,
        isError
    } = useFetch<CancellationData>(
        `/memberships/get-cancel-membership-data?membershipId=${member.id_g}`
    );

    const lastInvoiceDate = getLastInvoiceDate(member, cancelMembershipData?.invoices);
    const shouldShowLastInvoice = lastInvoiceDate.toMillis() > Date.now();

    const data = [
        [
            t('cancel_membership.confirmation.status'),
            <Bold key={uuidv4()}>{t('cancel_membership.confirmation.status_text')}</Bold>
        ],
        [
            t('cancel_membership.confirmation.cancellation_date'),
            <Bold key={uuidv4()}>{formatDate(member.cancel_date_g)}</Bold>
        ],
        [t('membership.endDate'), <Bold key={uuidv4()}>{formatDate(member.end_date_g)}</Bold>]
    ];

    if (shouldShowLastInvoice)
        data.push([
            t('cancel_membership.confirmation.invoice_date'),
            <Bold key={uuidv4()}>{formatDate(lastInvoiceDate)}</Bold>
        ]);

    if (isError) return null;

    return (
        <>
            <Heading size={5} className="mb-s" data-testid="cancelled-membership-title">
                {t('cancel_membership.confirmation.section_title')}
            </Heading>
            <Text
                size={2}
                className="mt-xs"
                data-testid="cancelled-membership-date"
                dangerouslySetInnerHTML={{
                    __html: t('cancel_membership.confirmation.description').replace(
                        '%end-date%',
                        `<strong>${getLocalDateString(member.end_date_g)}</strong>`
                    )
                }}
            />

            <div className="mt-m mb-xxl">
                {isLoading ? (
                    <Skeleton type="text" lines={3} />
                ) : (
                    <TableFlex size="s" data={data} />
                )}
            </div>
        </>
    );
};
