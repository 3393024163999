import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { CTACard } from '@src/components/Cards/CTACard.component';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { Loader } from '@src/components/Loader/Loader';
import { Modal } from '@src/components/Modals/Modal';
import { Discount, useDiscounts } from '@src/utils/hooks/api/useDiscounts';

import gtm from '../../../services/google-tag-manager-push';

const handleDiscount = (item: Discount) => {
    if (!item.has_landingspage__c) {
        window.open(item.external_link__c);
        gtm.advantageClicked(item.name);
    } else {
        gtm.advantageClicked(item.name, 'view campaign');
    }
};

const DiscountCards = ({
    data,
    setActive
}: {
    data: Discount[];
    setActive: (item: Discount) => void;
}) => {
    const { t } = useTranslation();

    const cards = data.map((item: Discount) => {
        // todo: date should use correct locale instead of nl only?
        const endDate = new Date(item.end_date__c).toLocaleDateString('nl');
        return (
            <div key={item.name} className="px-l sm:p-0">
                <CTACard
                    image={item.picture_link__c}
                    title={item.name}
                    caption={`${t('421')} ${endDate}`}
                    content={item.description_short__c}
                    button={{
                        text: t('422'),
                        onClick: (event) => {
                            handleDiscount(item);
                            setActive(item);
                            event.preventDefault();
                        }
                    }}
                />
            </div>
        );
    });
    return <div className="grid grid-cols-1 gap-l md:grid-cols-2 xl:grid-cols-3">{cards}</div>;
};

export const Advantage = () => {
    const { t } = useTranslation();
    const { discounts, isError, isLoading } = useDiscounts();

    const [modalContent, setModalContent] = useState<Discount>();
    const [modalOpen, setModalOpen] = useState(false);

    const handleActive = (item: Discount) => {
        if (item.has_landingspage__c) {
            setModalContent(item);
            setModalOpen(true);
        }
    };

    return (
        <>
            <Header
                title={t('35')}
                text={t('218')}
                image={<LineSVG id="vertical-lines" height={267} className="hidden xl:block" />}
                testid="advantage"
            />
            <Section>
                {isLoading && <Loader text={t('232')} className="mx-auto" />}
                {isError && <Text size={2}>{t('528')}</Text>}
                {discounts && discounts.length ? (
                    <DiscountCards data={discounts} setActive={handleActive} />
                ) : (
                    // There are no discounts available to you at the moment.
                    t('2071')
                )}
            </Section>
            <Modal isOpen={modalOpen} onModalClose={() => setModalOpen(false)}>
                <Heading size={5} className="text-center mb-s">
                    {modalContent?.name || ''}
                </Heading>
                <div
                    dangerouslySetInnerHTML={{ __html: modalContent?.description_long__c || '' }}
                />
                <a
                    data-testid={`${modalContent?.name}-advantage_modal-link`}
                    href={modalContent?.external_link__c || ''}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button className="mt-s" text={t('416')} />
                </a>
            </Modal>
        </>
    );
};
