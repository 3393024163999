import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Icon } from '@src/components/Icons/Icons';
import { useMember } from '@src/services/member/MemberProvider';

export const ChangeAccessCardWarning = () => {
    const { t } = useTranslation();

    const {
        state: { accessType }
    } = useMember();

    const isCardType = accessType === 'card';

    if (!isCardType) return null;
    return (
        <div className={'flex mb-xs gap-xxs'}>
            <Icon id={'info-outline'} className="flex items-center transform-none h-m" />
            <Text
                size={2}
                dangerouslySetInnerHTML={{
                    __html: t('change-access.card.warning')
                }}
            />
        </div>
    );
};
