import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Heading, Skeleton, Text } from '@basic-fit/design-system';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';
import { Error } from '@src/components/Form/Error/Error.component';
import { getFAQAnswer } from '@src/services/data/FAQAnswer.data';
import { getFromSessionCache } from '@src/services/utilities/sessionCache.service';
import { useFetch } from '@src/utils/hooks/api/useFetch';

import googleTagManagerPush from 'services/google-tag-manager-push';
import { Wrapper } from '../Layout/Wrapper';

interface FAQProps extends HTMLAttributes<HTMLDivElement> {
    category: string;
    totalVisible?: number;
}

export const FAQ = ({ category = 'all', totalVisible = 3 }: FAQProps): JSX.Element => {
    const {
        t,
        i18n: { language }
    } = useTranslation();

    const { data: FAQQuestions = Array(totalVisible).fill(null), isError: FAQError } = useFetch<
        Record<string, string>[]
    >(`/faq/per-category?category=${category}&language=${language}`);
    const [FAQAnswers, setFAQAnswers] = useState<Record<string, any>>({});

    const onQuestionClick = async (faq: any) => {
        const { id } = faq;
        if (FAQAnswers[id]) {
            return;
        }

        const answer = await getFAQAnswer(id, language);
        setFAQAnswers((prev) => ({ ...prev, [id]: answer }));

        googleTagManagerPush.question({ ...faq, answer });
    };

    useEffect(() => {
        if (FAQQuestions.some((item) => item)) {
            FAQQuestions.forEach(({ id }: any) => {
                const answerInSession = getFromSessionCache(`_FAQAnswer_${id}`);
                if (answerInSession) {
                    setFAQAnswers((prev) => ({ ...prev, [id]: answerInSession }));
                }
            });
        }
    }, [FAQQuestions]);

    return (
        <Wrapper>
            {/* title */}
            <div className="px-xs pt-xs sm:px-l sm:pt-l bg-mint lg:bg-none lg:pt-0">
                <div className="bg-white px-xs pt-xs sm:px-l sm:pt-l lg:pt-0">
                    <Heading size={5} className="mb-xxxs">
                        {t('65')}
                    </Heading>
                    <Text size={1}>{t('66')}</Text>
                </div>
            </div>
            <div className="px-l pb-l sm:px-xxl">
                {FAQError ? (
                    <div className="mt-xs">
                        <Error error={t('1527')} />
                    </div>
                ) : (
                    <>
                        {FAQQuestions.slice(0, totalVisible).map((item: any, index) => {
                            if (!item?.question) return null;
                            return (
                                <Accordion
                                    onClick={async () => await onQuestionClick(item)}
                                    title={item.question}
                                    key={index}
                                >
                                    {FAQAnswers[item?.id] ? (
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: FAQAnswers[item.id] || ''
                                            }}
                                        />
                                    ) : (
                                        <Skeleton type="text" lines={1} />
                                    )}
                                </Accordion>
                            );
                        })}
                        {/* show more */}
                        <div className="text-right">
                            <ButtonLink to="/service" className="mt-xxs">
                                {t('453')}
                            </ButtonLink>
                        </div>
                    </>
                )}
            </div>
        </Wrapper>
    );
};
