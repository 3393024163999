import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';
import { Icon } from '@src/components/Icons/Icons';
import { Section } from '@src/components/Layout/Section';
import { Skeleton } from '@src/components/Skeletons/Skeleton';
import { useAllIn } from '@src/utils/hooks/useAllIn';

export const AllInStatus = () => {
    const { t } = useTranslation();

    const { allInStatus, allInStatusLoading, allInStatusError } = useAllIn();

    const [stages, setStages] = useState<Array<Record<string, string | boolean>>>([]);

    const [currentStage, setCurrentStage] = useState(-1);

    useEffect(() => {
        if (allInStatus && !allInStatusLoading) {
            const { shipmentDetails, memberHasBike, paid } = allInStatus;
            setStages([
                {
                    status: t('all_in.status_page.payment_status'),
                    completed: paid
                },
                {
                    status: t('all_in.status_page.delivery_status'),
                    completed: shipmentDetails && shipmentDetails.url
                },
                { status: t('all_in.status_page.bike_delivered_status'), completed: memberHasBike }
            ]);
        }
    }, [allInStatus, allInStatusLoading]);

    useEffect(() => {
        setCurrentStage(stages.findIndex(({ completed }) => !completed));
    }, [stages]);

    return (
        <>
            {allInStatusLoading && (
                <Section>
                    <Skeleton type="text" lines={2.5} />
                </Section>
            )}

            {!allInStatusLoading && (!allInStatus || allInStatusError) && (
                <Section>
                    <Box variant="full-grey" size="small">
                        <Text size={2}>{t('all_in.status_page.status_unavailable')}</Text>
                    </Box>
                </Section>
            )}

            {!allInStatusLoading && !allInStatusError && allInStatus && (
                <Section>
                    <Box variant="full-grey" size="small">
                        <Heading size={5} className="mb-m">
                            {t('all_in.status_page.title')}
                        </Heading>

                        <Text size={2}>{t('all_in.status_page.sub_title')}</Text>

                        <div className="flex justify-between mt-s">
                            {stages.map(({ status, completed }, i) => (
                                <div
                                    key={`mbf-status__item-${i}`}
                                    className="flex flex-1 flex-col items-center relative pt-[12px]
                                        before:content-[''] before:absolute 
                                        before:top-[30px] before:right-0 before:left-0 
                                        before:h-[3px] before:bg-orange z-0 first:before:left-1/2 last:before:right-1/2"
                                >
                                    <div
                                        className={`bg-orange rounded-full flex justify-center items-center relative z-[1px]
                                            ${
                                                currentStage === i
                                                    ? 'w-[60px] h-[60px]'
                                                    : 'w-[35px] h-[35px]'
                                            }`}
                                    >
                                        {completed && (
                                            <Icon
                                                className={`bottom-[0px] text-[12px] text-white`}
                                                id="tick"
                                            />
                                        )}
                                    </div>
                                    <Text size={2}>{status}</Text>
                                </div>
                            ))}
                        </div>
                    </Box>

                    <Box variant="full-grey" size="small" className="mt-s mb-m">
                        {!allInStatus.memberHasBike &&
                            allInStatus.url &&
                            allInStatus.url !== 'false' && (
                                <div className="flex justify-between mb-s">
                                    <Text size={2}>
                                        {t('all_in.status_page.delivery_track_trace')}
                                    </Text>
                                    <ButtonLink
                                        className="font-bold"
                                        onClick={() => {
                                            window.open(
                                                allInStatus.url.match(/(http(s)?)/g)
                                                    ? allInStatus.url
                                                    : `//${allInStatus.url}`
                                            );
                                        }}
                                    >
                                        {t('all_in.status_page.delivery_partner_name')}
                                    </ButtonLink>
                                </div>
                            )}

                        <div className="flex justify-between mb-s">
                            <Text size={2}>{t('all_in.status_page.order_number')}</Text>
                            <Text size={2} className="font-bold">
                                {allInStatus.orderNumber}
                            </Text>
                        </div>

                        <div className="flex justify-between mb-s">
                            <Text size={2}>{t('all_in.status_page.request_date')}</Text>
                            <Text size={2} className="font-bold">
                                {new Date(allInStatus.createdDate).toLocaleDateString()}
                            </Text>
                        </div>
                    </Box>
                </Section>
            )}
        </>
    );
};
