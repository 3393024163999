import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Bold } from '@src/components/Text/Bold';
import { CancellationData } from '@src/pages/CancelMembership/CancelMembership.types';
import { useMember } from '@src/services/member/MemberProvider';
import { formatDate, getLocalDateString } from '@src/utils/helpers/date';
import { getTranslatedPaymentInterval } from '@src/utils/helpers/memberHelpers';
import { PaymentInterval } from '@src/utils/helpers/priceHelpers';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { DateTime } from 'luxon';

export const ContractDetails = () => {
    const { t } = useTranslation();
    const { state: member } = useMember();
    const { data: cancellationData } = useFetch<CancellationData>(
        `/memberships/get-cancel-membership-data?membershipId=${member.id_g}`
    );

    const { cancels_automatically, minimal_contract_period, interval } = member.payment_plan || {};
    const isMemberFrozen = member?.peopleMembership?.membershipStatus === 'Frozen';

    // When a member has cancelled their membership, otherwise it's null
    const endDateGM = member.end_date_g;
    const autoCancel = cancels_automatically;
    // If the member has not cancelled their membership, use standard contract end date
    const contractEndDate = endDateGM || member.membership.contract_end_date_g;
    const contractLength = getTranslatedPaymentInterval(minimal_contract_period);
    const nextPaymentDate = member.debits?.startDate;
    const paymentInterval = getTranslatedPaymentInterval(interval);
    const autoRenewal = !endDateGM && !autoCancel;
    const inContract = DateTime.fromISO(contractEndDate) > DateTime.now();
    const isFlex =
        minimal_contract_period === PaymentInterval.FourWeeks &&
        interval === PaymentInterval.FourWeeks;

    const getContractDescription = () => {
        if (isFlex && autoRenewal) {
            return t('membership.flexAutoRenewal')
                .replace('%renewalcontractperiod%', `<strong>${paymentInterval}</strong>`)
                .replace('%notice%', `<strong>${paymentInterval}</strong>`);
        } else if (inContract && autoRenewal) {
            return t('contract.details.running.auto.renewal')
                .replace('%date%', `<strong>${getLocalDateString(contractEndDate)}</strong>`)
                .replace('%contractPeriod%', `<strong>${contractLength}</strong>`)
                .replace('%paymentInterval%', `<strong>${paymentInterval}</strong>`);
        } else if (inContract && !autoRenewal) {
            return t('contract.details.running.no.renewal')
                .replace('%contractPeriod%', `<strong>${contractLength}</strong>`)
                .replace('%date%', `<strong>${getLocalDateString(contractEndDate)}</strong>`);
        } else if (!inContract && autoRenewal) {
            return t('contract.details.stopped.auto.renewal')
                .replace('%contractPeriod%', `<strong>${contractLength}</strong>`)
                .replace('%paymentInterval%', `<strong>${paymentInterval}</strong>`);
        } else {
            return '';
        }
    };

    const [endDate, setEndDate] = useState('');
    const [contractDescription, setContractDescription] = useState('');
    const [cancellationDescriptionKey, setCancellationDescriptionKey] = useState(
        'membership.cancellationGeneric'
    );

    const [tableData, setTableData] = useState([
        [
            t('41'),
            <Bold key={member.membership.display_duration}>
                {member.membership.display_duration}
            </Bold>
        ],
        [
            t('43'),
            <Bold key={member.membership.start_date_g}>
                {formatDate(member.membership.start_date_g)}
            </Bold>
        ]
    ]);

    useEffect(() => {
        if (cancellationData?.dates?.length > 0) {
            setEndDate(formatDate(cancellationData.dates[0].cancelDate));
        }
    }, [cancellationData]);

    useEffect(() => {
        if (isMemberFrozen) {
            setTableData([
                [
                    t('freeze.membership.status'),
                    <Bold key="frozen">{t('freeze.membership.status.frozen')}</Bold>
                ],
                ...tableData
            ]);
        }
    }, [isMemberFrozen]);

    useEffect(() => {
        if (member?.payment_plan?.cancels_automatically && member.end_date_g) {
            const contractEndDate = formatDate(member.end_date_g);
            setEndDate(contractEndDate);
            setCancellationDescriptionKey('membership.autoCancellation.description');
            setTableData([
                ...tableData,
                [
                    t('membership.endDate'),
                    <Bold key={member.end_date_g}>{formatDate(member.end_date_g)}</Bold>
                ]
            ]);
        }

        if (nextPaymentDate) {
            setTableData((prevState) => [
                ...prevState,
                [t('membership.nextPayment'), <Bold key={nextPaymentDate}>{nextPaymentDate}</Bold>]
            ]);
        }

        setContractDescription(getContractDescription());
    }, []);

    return (
        <>
            <Heading size={5} className="mb-s">
                {t('membership.contractInformation')}
            </Heading>
            {contractDescription && (
                <Text data-testid="contract-details" size={2} className="my-xs">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: contractDescription
                        }}
                    />
                </Text>
            )}
            <TableFlex className="mb-xs" data={tableData} size="s" />
            {endDate && (
                <Text size={1} className="mb-l mt-xs">
                    {t(cancellationDescriptionKey).replace('%date%', endDate)}
                </Text>
            )}
        </>
    );
};
