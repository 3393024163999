import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Icon } from '@src/components/Icons/Icons';

interface UspProps {
    uspText: string;
    iconColor?: string;
}

export const Usp = ({ uspText, iconColor = 'text-orange' }: UspProps) => {
    const { t } = useTranslation();

    return (
        <ul>
            {t(uspText)
                .split('%%li%%')
                .map((text: string, index: number) => (
                    <li key={`usp-${index}`}>
                        <div className="flex items-center mb-[5px]">
                            <Icon
                                className={`bottom-[0px] text-[12px] mr-[10px] ${iconColor}`}
                                id="tick"
                            />
                            <Text size={2}>{text.trim()}</Text>
                        </div>
                    </li>
                ))}
        </ul>
    );
};
