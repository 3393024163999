import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Button } from '@src/components/Buttons/Button';
import { useCancellationContext } from '@src/components/ContextProvider/CancellationContextProvider';
import { Header } from '@src/components/Header/Header';
import { Section } from '@src/components/Layout/Section';
import { LineSVG } from '@src/components/LineSVG/LineSVG';
import { MemberPriceContract } from '@src/components/Price/MemberPriceContract';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Bold } from '@src/components/Text/Bold';
import { CancellationData } from '@src/pages/CancelMembership/CancelMembership.types';
import { Path } from '@src/router/CancelMembership.router';
import { cancelMembership } from '@src/services/data/cancelMembership.data';
import { useMember } from '@src/services/member/MemberProvider';
import { formatDate } from '@src/utils/helpers/date';
import { getLastInvoiceDate, getMemberName, hasAccessCard } from '@src/utils/helpers/memberHelpers';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { useSWRConfig } from 'swr';
import { v4 as uuidv4 } from 'uuid';

import googleTagManagerPush from 'services/google-tag-manager-push';
import { toLocal } from '../../../services/date-converter';

export default function CancelMembershipCheck(): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { chosenReason, chosenDate, reasonDescription } = useCancellationContext();
    const { mutate } = useSWRConfig();
    const { state: member } = useMember();

    const { data } = useFetch<CancellationData>(
        `/memberships/get-cancel-membership-data?membershipId=${member.id_g}`
    );

    const lastInvoiceDate = formatDate(getLastInvoiceDate(member, data.invoices));

    useEffect(() => {
        if (!chosenDate?.cancelDate || !chosenReason?.reason) navigate(Path.Base + Path.Reasons);
        googleTagManagerPush.cancelMembership('view', 'page payment details');
    }, []);

    async function cancelMembershipHandle() {
        if (isLoading) return;

        const isInvalidData = !member.id_g || !chosenDate?.cancelDate || !chosenReason?.reason;
        if (isInvalidData) return;

        googleTagManagerPush.cancelMembership(
            'click',
            `page dates - picked date option ${chosenDate.listIndex ?? 0}`
        );
        googleTagManagerPush.cancelMembership('click', 'page payment details - continue');
        setIsLoading(true);

        try {
            await cancelMembership({
                endDate: chosenDate.cancelDate,
                peopleMembershipId: member.id_g,
                cancelReason: chosenReason.reason,
                reasonDescription: reasonDescription
            });
            await mutate('/member/get-member');
            navigate(Path.Base + Path.Confirmation);
        } catch (e) {
            setError(true);
        } finally {
            setIsLoading(false);
        }
    }

    /**
     * Collect cancellation data to display in a table
     */
    const cancelDetailsData = [
        [t('151'), <Bold key={uuidv4()}>{getMemberName(member)}</Bold>],
        [
            t('membership.cancellation.payment.last_payment'),
            <Bold key={uuidv4()}>{lastInvoiceDate}</Bold>
        ],
        [t('435'), <Bold key={uuidv4()}>{toLocal(member.membership.contract_end_date_g)}</Bold>],
        [t('40'), <Bold key={uuidv4()}>{member.membership.display_name}</Bold>],
        [t('100'), <MemberPriceContract key={uuidv4()} className="justify-end" />]
    ];

    if (hasAccessCard(member)) {
        cancelDetailsData.push([t('409'), <Bold key={uuidv4()}>{member.card_number_s}</Bold>]);
    }

    return (
        <div data-cy="cancel-membership__check">
            {/* cancelmembership & Sorry that you wanrt to stop */}
            <Header
                data-cy="cancel-membership__check__header"
                title={t('117')}
                text={t('538')}
                image={<LineSVG id="vertical-lines" height={267} />}
            />

            <Section>
                <Box variant="white">
                    <Heading size={5}>{t('membership.cancellation.payment.title')}</Heading>
                    <Text size={2} className="mt-xxs">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('membership.cancellation.payment.details')
                            }}
                        ></span>
                    </Text>
                    <Text size={2} className="mt-s mb-xs">
                        <strong className="font-heading-treble font-bold">{t('379')}</strong>
                    </Text>
                    <TableFlex data={cancelDetailsData} size="s" />
                </Box>
            </Section>

            <Section
                data-cy="cancel-membership__check__buttons"
                className="flex justify-center items-center"
            >
                <div className="w-[315px] flex-col justify-center flex">
                    {/*  Something went wrong, please try again */}
                    {error && (
                        <Text
                            data-cy="cancel-membership__check__no-choice-alert"
                            className="!block text-red mb-[20px] text-center"
                            size={2}
                        >
                            {t('1527')}
                        </Text>
                    )}

                    {/* next button */}
                    <Button
                        data-cy="cancel-membership__check__button-next"
                        onClick={cancelMembershipHandle}
                        disabled={isLoading}
                        loading={isLoading}
                        className="mb-[10px]"
                        text={t('102')}
                    />

                    {/* go back */}
                    <Button
                        data-cy="cancel-membership__check__button-back"
                        onClick={() => {
                            googleTagManagerPush.cancelMembership(
                                'click',
                                'page payment details - go back'
                            );
                            navigate(Path.Base + Path.Dates);
                        }}
                        className="!bg-white hover:bg-white text-anthracite"
                        text={t('162')}
                    />
                </div>
            </Section>
        </div>
    );
}
