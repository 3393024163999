import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { StrokeText } from '@src/components/StrokeText/StrokeText';
import { useMember } from '@src/services/member/MemberProvider';
import { getMembershipUspByCountry } from '@src/utils/helpers/uspHelpers';

import { Membership } from '../ChangeMembershipFlow.types';
import { FormattedUSP } from './FormattedUSP';

type AreYouSureModalProps = {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    onContinue: () => void;
};

export const AreYouSureModal = ({ showModal, setShowModal, onContinue }: AreYouSureModalProps) => {
    const { t } = useTranslation();
    const { state: member } = useMember();
    const { mailing_country: country } = member || {};

    return (
        <Modal isOpen={showModal} onModalClose={() => setShowModal(false)}>
            <StrokeText
                stroke="last"
                color="anthracite"
                className="text-anthracite mb-4"
                text={t('membership.change.modal.sure')}
            />
            <Text size={2} className="mb-4">
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('membership.change.modal.sure.text')
                    }}
                />
            </Text>
            <div className="mb-8">
                {getMembershipUspByCountry(Membership.PREMIUM, country).map((benefit) => (
                    <FormattedUSP key={benefit} id={benefit} country={country} size={2} />
                ))}
            </div>

            <Button
                text={t('membership.change.modal.sure.backButton')}
                data-cy="are-you-sure__back-button"
                onClick={() => setShowModal(false)}
            />
            <Button
                theme="outline"
                text={t('membership.change.modal.sure.continueButton')}
                className="mt-4"
                data-cy="are-you-sure__continue-button"
                onClick={onContinue}
            />
        </Modal>
    );
};
