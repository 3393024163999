import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMember } from '@src/services/member/MemberProvider';
import { getChangeMembershipData } from '@src/services/membership.service';
import { RootState } from '@src/types/store.type';

import { useFetch } from './api/useFetch';
import { useMBFFeatures } from './api/useMBFFeatures';

export const useAllIn = () => {
    const dispatch = useDispatch();
    const { state: member } = useMember();
    const { changeMembershipAllIn } = useMBFFeatures();

    const { changeMembershipOptions } = useSelector((state: RootState) => {
        const changeMembershipOptions =
            state.changeMembershipOptionsReducer.changeMembershipOptions;
        return {
            changeMembershipOptions
        };
    });

    const [allInPrice, setAllInPrice] = useState(0);

    const [isAllInPurchaseAllowed, setIsAllInPurchaseAllowed] = useState<boolean | null>(null);

    const [hasAllInMembership, setHasAllInMembership] = useState(member.isAllIn);

    const {
        data: allInStatus,
        isError,
        isLoading
    } = useFetch<any>(hasAllInMembership ? '/memberships/get-all-in-status' : null);

    const { data: changeMembershipData, isError: hasChangeMembershipError } = useFetch<any>(
        member.id_g
            ? `/memberships/get-people-membership-change?peopleMembershipId=${member.id_g}`
            : null
    );

    useEffect(() => {
        setHasAllInMembership(member.isAllIn);

        if (member.isAllIn) {
            setIsAllInPurchaseAllowed(true);
        }
    }, [member]);

    useEffect(() => {
        if (hasChangeMembershipError) {
            console.error('Error in fetching change membership options', hasChangeMembershipError);
        }
    }, [hasChangeMembershipError]);

    useEffect(() => {
        if (!changeMembershipOptions && !hasAllInMembership && changeMembershipData) {
            const { fourWeeks, _52Weeks, unknown } = getChangeMembershipData(
                member,
                changeMembershipData.membership,
                changeMembershipAllIn
            );
            dispatch({
                type: 'CHANGE_MEMBERSHIP_OPTIONS',
                payload: { fourWeeks, _52Weeks, unknown }
            });

            if (
                fourWeeks.some(
                    (paymentSchedule: Record<string, unknown>) =>
                        paymentSchedule.membershipType === 'all-in'
                )
            ) {
                setIsAllInPurchaseAllowed(!member.isEmployee);
                return;
            }

            setIsAllInPurchaseAllowed(false);
        }
    }, [changeMembershipData]);

    useEffect(() => {
        if (changeMembershipOptions) {
            const { fourWeeks } = changeMembershipOptions;
            const { intervalPrice } = fourWeeks.find(
                (paymentSchedule: Record<string, unknown>) =>
                    paymentSchedule.membershipType === 'all-in'
            ) || { intervalPrice: 0 };

            setAllInPrice(intervalPrice);
            setIsAllInPurchaseAllowed(!!intervalPrice);
        }
    }, [changeMembershipOptions]);

    return {
        isAllInPurchaseAllowed,
        allInPrice,
        hasAllInMembership: member.isAllIn,
        allInStatus,
        allInStatusLoading: isLoading,
        allInStatusError: isError
    };
};
