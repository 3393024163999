import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@src/components/Icons/Icons';

export const BestChoiceRibbon = () => {
    const { t } = useTranslation();

    return (
        <div className="flex items-center relative mt-2 w-[70px] h-[70px] rotate-[10deg]">
            <Icon className="absolute z-0 top-0 left-0 !w-full !h-full" id="ribbon-green" />
            <span
                className="relative z-10 font-heading-treble font-extrabold uppercase text-[11px] leading-none text-center"
                dangerouslySetInnerHTML={{ __html: t('membership.bestChoice') }}
            ></span>
        </div>
    );
};
