import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Section } from '@src/components/Layout/Section';
import { Skeleton } from '@src/components/Skeletons/Skeleton';
import { AllInOverview } from '@src/pages/AllIn/components/AllInOverview.component';
import { AllInStatus } from '@src/pages/AllIn/components/AllInStatus.component';
import { HeroBanner } from '@src/pages/AllIn/components/HeroBanner.component';
import { useAllIn } from '@src/utils/hooks/useAllIn';

export const AllInPage = (): JSX.Element => {
    const { t } = useTranslation();
    const { hasAllInMembership, isAllInPurchaseAllowed } = useAllIn();

    return (
        <section className="mbf-all-in">
            {isAllInPurchaseAllowed === null ? (
                <Skeleton type="page" />
            ) : (
                <>
                    <HeroBanner />
                    {hasAllInMembership && <AllInStatus />}

                    {!hasAllInMembership && isAllInPurchaseAllowed && <AllInOverview />}
                    {!isAllInPurchaseAllowed && !hasAllInMembership && (
                        <Section>
                            <Box variant="full-grey" size="small">
                                <Text size={1}>
                                    {t('all_in.landing_page.membership_not_available')}
                                </Text>
                            </Box>
                        </Section>
                    )}
                </>
            )}
        </section>
    );
};
