import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Heading } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Button } from '@src/components/Buttons/Button';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';
import { Section } from '@src/components/Layout/Section';
import { Usp } from '@src/pages/AllIn/components/usp.component';
import { getPrice } from '@src/utils/helpers/priceHelpers';
import { useAllIn } from '@src/utils/hooks/useAllIn';

export const AllInOverview = () => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const navigate = useNavigate();

    const { allInPrice } = useAllIn();

    return (
        <>
            <Section>
                <Box variant="mint-top">
                    <div className="flex justify-between">
                        <Heading size={5} className="mb-m">
                            {t('all_in.landing_page.heading')}
                        </Heading>
                        <ButtonLink
                            onClick={() =>
                                window.open(
                                    t('all_in.landing_page.more_info_link') ||
                                    'https://www.basic-fit.com'
                                )
                            }
                        >
                            {t('all_in.landing_page.more_info_text')}
                        </ButtonLink>
                    </div>

                    <Usp uspText="all_in.landing_page.main_usp" />
                </Box>
            </Section>

            <Section className="sm:mt-0 md:mt-0">
                <Box variant="orange" size="small" className="mb-xxl">
                    <div className="flex justify-between pb-xs border-b border-solid border-anthracite mb-s">
                        <Heading size={6}>{t('per4Weeks')}</Heading>
                        <Heading size={6}>{getPrice(allInPrice, language)}</Heading>
                    </div>

                    <Usp uspText="all_in.landing_page.4_weeks_usp" iconColor="text-mint" />
                </Box>
            </Section>

            <div className="flex justify-center">
                <div className="mb-[30px]">
                    <Button
                        text={t('all_in.landing_page.upgrade_membership')}
                        className="px-s"
                        onClick={() => navigate('/change-membership')}
                    />
                </div>
            </div>
        </>
    );
};
