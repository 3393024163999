import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Button } from '@src/components/Buttons/Button';
import { CopySsoLinkButton } from '@src/components/Buttons/CopySsoLinkButton';
import { ProofOfPaymentModal } from '@src/pages/Payments/components/ProofOfPaymentModal';
import { isApp, isIOS } from '@src/utils/helpers/window';
import { useSsoUrl } from '@src/utils/hooks/api/useSsoUrl';

export const ProofOfPayment = () => {
    const { t } = useTranslation();
    const { ssoUrl } = useSsoUrl(isApp() && !isIOS()); // prefetch for android

    const [modalOpen, setModalOpen] = useState(false);

    const getAppText = () => {
        let appText = t('payments.pop.bfa_text');
        appText = appText.replace(
            '[',
            `<a class='cursor-pointer text-orange' onclick="(() => window.open('${ssoUrl}'))()" data-cy="proof-of-payment-link">`
        );
        appText = appText.replace(']', '</a>');
        return appText;
    };

    return (
        <>
            <Box variant="grey" data-cy="proof-of-payment">
                <Heading size={5} className="mb-s">
                    {t('payments.pop.title')}
                </Heading>

                {!isApp() && (
                    <>
                        <Text size={2} className="my-xs">
                            {t('payments.pop.text')}
                        </Text>
                        <Button
                            theme="outline"
                            text={t('payments.pop.button_text')}
                            className="mt-s w-full sm:w-1/2 m-auto"
                            onClick={() => setModalOpen(true)}
                        />
                    </>
                )}

                {isApp() && (
                    <>
                        {!isIOS() && (
                            <Text size={2} className="my-xs">
                                <span dangerouslySetInnerHTML={{ __html: getAppText() }} />
                            </Text>
                        )}

                        {isIOS() && (
                            <>
                                <Text size={2} className="my-xs">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t('payments.pop.bfa_copy_text')
                                        }}
                                    />
                                </Text>
                                <CopySsoLinkButton
                                    textStart={t('generic.copy_link')}
                                    textDone={t('generic.link_copied')}
                                    data-cy="proof-of-payment-copy-link"
                                    theme="outline"
                                />
                            </>
                        )}
                    </>
                )}
            </Box>

            <ProofOfPaymentModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
        </>
    );
};
