import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@basic-fit/design-system';
import { StrokeText } from '@src/components/StrokeText/StrokeText';

export const HeroBanner = () => {
    const { t } = useTranslation();

    return (
        <div
            className={`
              mbf-banner relative bg-gradient-anthracite overflow-hidden
              md:min-h-[314px] xl:min-h-[460px] max-h-[500px]
            `}
        >
            <div className="flex-grow hidden md:block pointer-events-none absolute left-0 bottom-0 right-0 top-0">
                <img
                    className="w-full object-cover"
                    src={t('all_in.landing_page.banner.image')}
                    alt="All-In Hero Banner"
                />
            </div>
            <div
                className={`
                    flex flex-col flex-shrink-0 w-[100%] xl:w-[50%] md:w-[60%] justify-center relative
                    px-[15px] pt-[15px] sm:pt-[30px] sm:px-[30px] md:pt-[90px] md:pl-[60px]
                `}
            >
                <div>
                    <StrokeText
                        stroke="last"
                        color="white"
                        className="md:text-[58px] md:leading-[60px] lg:text-[58px] lg:leading-[60px]"
                        text={t('all_in.landing_page.banner.go')}
                    />
                    <StrokeText
                        className="md:text-[58px] md:leading-[60px] lg:text-[58px] lg:leading-[60px]"
                        text={t('all_in.landing_page.banner.all-in')}
                    />
                    <Heading size={7} color="white" className="w-full sm:w-5/6 my-[20px]">
                        {t('all_in.landing_page.banner.description')}
                    </Heading>
                </div>
            </div>
        </div>
    );
};
